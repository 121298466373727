import logo from "../logo.svg";
import "../App.css";

import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import ServicesSection from "../components/ServicesSection";
import DownloadSection from "../components/DownloadSection";
import ContactSection from "../components/ContactSection";
import GreenSection from "../components/GreenSection";
import FooterSection from "../components/FooterSection";
import styled from "styled-components";
import background from "../images/bg.png";
import vectorSVG from "../images/Vectorvector.svg";
import Catalogo from "../Catalogo/Catalogo";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  font-family: "Dosis", sans-serif; /* Aplica la tipografía Dosis a todo el sitio */
`;

const HeadBg = styled.div`
  padding-top: 3%;
  background-image: url(${background});
  background-color: #dddddd;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  position: relative; /* Agregamos posición relativa para que VectorBackground pueda posicionarse correctamente */
`;

const VectorBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Ajusta la altura para que se sobreponga en el fondo de Hero */
  pointer-events: none;
  background-image: url(${vectorSVG});
  background-size: contain;
  background-repeat: repeat-x;
`;

const Home = () => {
  const location = useLocation();
  const nosotros = useRef();
  const serv = useRef();
  const contacto = useRef();

  const handleClickNosotros = () => {
    nosotros.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickServicios = () => {
    serv.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (location.hash === "#nosotros") {
      nosotros.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#serv") {
      serv.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <Wrapper>
      <HeadBg>
        <Navbar
          handleClickServicios={handleClickServicios}
          handleClickNosotros={handleClickNosotros}
          handleClickContacto={handleClickContacto}
        />
        <Hero />
        <VectorBackground />
      </HeadBg>
      <AboutUs reference={nosotros} />
      <ServicesSection reference={serv} />
      <DownloadSection />
      <ContactSection />
      <GreenSection />
      <FooterSection reference={contacto} />
    </Wrapper>
  );
};

export default Home;
