import React, { useEffect, useState } from "react";
import styled from "styled-components";
import appStoreIcon from "../images/appstore.png";
import googleIcon from "../images/google-play.png";
import appImg1 from "../images/VENDER-app-mockup-v3 1.png";
import vendedor from "../images/VENDER-img-repartidor 2.png";

const Section = styled.section`
  padding-top: 2%;
  text-align: center;
  background-color: #fff;
`;

const Title = styled.h2`
  color: #ff8925;
  text-align: center;

  /* Dosis-Desktop/title-3col-semibold */
  font-family: Dosis;
  font-size: 3.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.11813rem;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Text = styled.p`
  color: #152500;
  text-align: center;

  /* Dosis-Desktop/body-3col-medium */
  font-family: Dosis;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem; /* 136% */
  letter-spacing: 0.04688rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 20%;
`;

const Circle = styled.div`
  position: absolute;
  transform: translate(-70%, 10%);
  width: 18vw;
  height: 36vh;
  border-radius: 50%;
  background: linear-gradient(180deg, #ff8925 0%, #ff7425 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 15vh;
    transform: translate(-70%, 0%);
  }
`;

const CircleText = styled.p`
  padding: 5%;
  color: #fff;
  text-align: center;
  font-family: Dosis;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.11463rem;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const CircleRight = styled.div`
  position: absolute;
  transform: translate(160%, 250%);
  width: 10vw;
  height: 20vh;
  border-radius: 50%;
  background: linear-gradient(0deg, #91dc30 0%, #5fc62f 38.54%, #50b85b 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    transform: ${({ isMobile }) =>
      isMobile ? "translate(2%, 15%)" : "translate(130%, 180%)"};
    width: 12vw;
    height: 12vh;
  }
`;

const CircleRight2 = styled.div`
  position: absolute;
  transform: translate(470%, 400%);
  width: 5vw;
  height: 10vh;
  border-radius: 50%;
  background: linear-gradient(180deg, #91dc30 0%, #5fc62f 38.54%, #50b85b 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    transform: ${({ isMobile }) =>
      isMobile ? "translate(3%, 25%)" : "translate(670%, 400%)"};
    width: 3.5vw;
    height: 3.5vh;
  }
`;

const FirstImage = styled.img`
  width: 20%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// Styled-component para SecondImage con translateY como propiedad
const SecondImage = styled.img`
  width: 60%;
  transform: ${({ translateY }) => translateY};
  transition: transform 1s ease;
`;

const StoreImagesContainer = styled.div`
  margin-top: 1%;
  display: flex;
  align-items: end;
  justify-content: center;
`;

const StoreImage = styled.img`
  width: 3.5rem;
  border-radius: 0.9375rem;
  border: 2px solid #fff;
  padding: 15%;
  /* shadow-boton */
  box-shadow: 0px 3px 7px 0px rgba(21, 37, 0, 0.15);
  @media (max-width: 768px) {
    margin-top: 10%;
    width: 2.5rem;
  }
`;
const StoreImage2 = styled.img`
  width: 4.5rem;
  border-radius: 0.9375rem;
  border: 2px solid #fff;

  /* shadow-boton */
  box-shadow: 0px 3px 7px 0px rgba(21, 37, 0, 0.15);
  @media (max-width: 768px) {
    margin-top: 10%;
    width: 2.5rem;
  }
`;

const StoreText = styled.p`
  align-items: center;
  color: #152500;
  text-align: center;

  /* Dosis-Desktop/caption-2col-medium */
  font-family: Dosis;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02813rem;
`;

const DownWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
`;

const DownloadSection = () => {
  const [scrollOffset, setScrollOffset] = useState(0);
  const is4K = window.innerWidth >= 2540;
  const maxHeight = is4K ? 550 : 280;

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrollOffset(offset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Función para obtener el valor de translateY
  const getTranslateY = () => {
    const offset = window.scrollY;
    const windowHeight = window.innerHeight;
    const isMobileDevice = window.innerWidth <= 768; // Define aquí tu ancho máximo para considerar dispositivos móviles

    if (isMobileDevice) {
      return `translateY(${offset / 25}px)`;
    } else {
      const is4K = window.innerWidth >= 2540;
      const maxHeight = is4K ? 550 : 280;
      return `translateY(${Math.min((offset - 700) / 4.5, maxHeight)}px)`;
    }
  };

  return (
    <Section>
      <Title>¡Descarga nuestra app!</Title>
      <Text>Ahora es más fácil ordenar productos para tu negocio.</Text>
      <ImagesContainer>
        <Circle>
          <CircleText>¡Pide ahora! más rápido y más fácil</CircleText>
        </Circle>
        <CircleRight />
        <FirstImage src={vendedor} alt="Imagen 1" scrollOffset={scrollOffset} />
        <SecondImage
          src={appImg1}
          alt="Imagen 2"
          translateY={getTranslateY()} // Pasamos el valor de translateY como propiedad
        />
        <CircleRight2 />
      </ImagesContainer>
      <Text>Encuéntrala como Vender de León en tu tienda digital:</Text>
      <StoreImagesContainer>
        <DownWrap>
          <StoreImage2 src={appStoreIcon} alt="App Store" />
          <StoreText>App Store</StoreText>
        </DownWrap>
        <DownWrap>
          <StoreImage src={googleIcon} alt="Google Play" />
          <StoreText>Google Play</StoreText>
        </DownWrap>
      </StoreImagesContainer>
    </Section>
  );
};

export default DownloadSection;
