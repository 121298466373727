import React from "react";
import styled from "styled-components";
import phoneIcone from "../images/phone_black_24dp.png";
import mailIcon from "../images/email_black_24dp.png";
import locIcon from "../images/location_on_black_24dp.png";
import bg from "../images/caja_dtos.png";

const Section = styled.section`
  background-color: #ff8925;
  padding: 30px 0;

  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const RectangleContainer = styled.div`
  background-color: white;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const FirstColumn = styled.div`
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  @media (min-width: 769px) {
    flex: 0 0 50%;
    padding-right: 10px;
  }
`;

const FirstColumnText = styled.p`
  color: #152500;
  text-align: center;
  margin-bottom: 0;
  /* Dosis-Desktop/body-3col-medium */
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.04688rem;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const OrangeText = styled.p`
  color: #ff8925;
  text-align: center;
  margin-top: 0;
  /* Dosis-Desktop/title-3col-semibold */
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.09563rem;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const SecondColumn = styled.div`
  text-align: left;
  @media (min-width: 769px) {
    flex: 0 0 50%;
    padding-left: 10px;
    border-left: 1.5px dotted black;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const Icon = styled.img`
  width: 24px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 20px;
  }
`;

const TextSecondColumn = styled.p`
  font-size: 16px;
  margin: 0;
  line-height: 1.25rem;
  color: #152500;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.04688rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ContactSection = () => {
  return (
    <Section>
      <RectangleContainer>
        <FirstColumn>
          <FirstColumnText>¿Tienes alguna duda?</FirstColumnText>
          <OrangeText>Contáctanos</OrangeText>
        </FirstColumn>
        <SecondColumn>
          <IconContainer>
            <Icon src={phoneIcone} alt="Teléfono" />
            <TextSecondColumn>(000) 000 00 00</TextSecondColumn>
          </IconContainer>
          <IconContainer>
            <Icon src={mailIcon} alt="Email" />
            <TextSecondColumn>hola@venderdeleon.com</TextSecondColumn>
          </IconContainer>
          <IconContainer>
            <Icon src={locIcon} alt="Ubicación" />
            <TextSecondColumn>
              Calle #000, colonia, CP. Municipio, Estado.
            </TextSecondColumn>
          </IconContainer>
        </SecondColumn>
      </RectangleContainer>
    </Section>
  );
};

export default ContactSection;
