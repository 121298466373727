import React from "react";
import styled from "styled-components";
import catalogIcon from "../images/icon-catalogo_de_producto.png";
import pedidosIcon from "../images/icon-pedidos_hasta_tu_negocio.png";
import atencionIcon from "../images/icon-atencion_personalizada.png";

const Section = styled.section`
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  border-top: 1px dotted #ccc;
  background-color: white;
`;

const Column = styled.div`
  flex: 0 0 30%;
  text-align: center;
  padding: 20px;
  &:not(:last-child) {
    border-right: 1.5px dotted black;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
    margin-bottom: 20px;
    &:not(:last-child) {
      border-right: none;
    }
  }
`;

const Image = styled.img`
  width: 40%;
  max-width: 12.5rem;
  border-radius: 50%;
`;

const Title = styled.p`
  color: #152500;
  text-align: center;

  /* Dosis-Desktop/title-2col-semibold */
  font-family: Dosis;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.05813rem;
`;

const ServicesSection = (props) => {
  return (
    <Section ref={props.reference}>
      <Column>
        <Image src={catalogIcon} alt="Catálogo de productos" />
        <Title>Catálogo de productos</Title>
      </Column>
      <Column>
        <Image src={pedidosIcon} alt="Pedidos hasta tu negocio" />
        <Title>Pedidos hasta tu negocio</Title>
      </Column>
      <Column>
        <Image src={atencionIcon} alt="Atención personalizada" />
        <Title>Atención personalizada</Title>
      </Column>
    </Section>
  );
};

export default ServicesSection;
