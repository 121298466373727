import React from "react";
import styled from "styled-components";
import SideImage from "../images/Rounded rectangle.png";

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column; /* Cambiamos la dirección del flex para que los elementos se apilen en pantallas pequeñas */
  align-items: center; /* Centramos los elementos horizontalmente */
  padding-top: 5%;
  max-width: fit-content;
  @media (min-width: 768px) {
    /* A partir de 768px de ancho, cambiamos a flexbox con dos columnas */
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 100%;
  max-width: 480px; /* Limitamos el ancho del LeftColumn para que no sea demasiado grande en pantallas grandes */
  border-radius: 25px;
  padding: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    /* A partir de 768px de ancho, ajustamos el ancho del LeftColumn para que ocupe la mitad del contenedor */
    flex: 0 0 50%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const RightColumn = styled.div`
  flex: 0 0 100%;
  max-width: 450px; /* Limitamos el ancho del RightColumn para que no sea demasiado grande en pantallas grandes */
  padding: 0 40px;
  @media (min-width: 768px) {
    /* A partir de 768px de ancho, ajustamos el ancho del RightColumn para que ocupe la mitad del contenedor */
    flex: 0 0 50%;
  }
`;

const Title = styled.h2`
  color: #ff8925;
  /* Dosis-Desktop/title-3col-semibold */
  font-family: Dosis;
  font-style: normal;
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09563rem;
`;

const Subtitle = styled.h3`
  color: #60c730;
  /* Dosis-Desktop/title-2col-semibold */
  font-family: Dosis;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.05813rem;
  margin-bottom: 0;
  margin-left: 5%;
`;

const Paragraph = styled.p`
  color: #152500;
  /* Dosis-Desktop/body-2col-medium */
  font-family: Dosis;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03563rem;
  margin-left: 5%;
  margin-bottom: 5%;
`;

const DashedLine = styled.hr`
  width: 35%;
  margin-top: 0;
  margin-left: 0;
  border: none;
  height: 1px;
  background: transparent;
  border-top: 2px dotted #152500;
`;

const AboutUs = (props) => {
  return (
    <CenteredContainer ref={props.reference}>
      <Section>
        <LeftColumn>
          <Image src={SideImage} alt="Imagen" />
        </LeftColumn>
        <RightColumn>
          <Title>NOSOTROS</Title>
          <Subtitle>Misión</Subtitle>
          <DashedLine />
          <Paragraph>
            Continuar siendo por excelencia Distribuidores Horizontales en la
            Zona Bajío, llevando productos de marcas reconocidas al alcance del
            consumidor final, posicionando las marcas de empresas nacionales y
            extranjeras con las que colaboramos como socios comerciales.
          </Paragraph>
          <Subtitle>Visión</Subtitle>
          <DashedLine />
          <Paragraph>
            Facilitar a nuestros clientes la obtención de productos de consumo
            diario y primera necesidad de forma fácil y ágil con ayuda de
            aplicaciones tecnológicas; ahorrando tiempo y costo para beneficio
            de su negocio.
          </Paragraph>
          <Subtitle>Valores</Subtitle>
          <DashedLine />
          <ul>
            <li>
              <Paragraph>
                <strong>Servicio. </strong>Apoyo y disposición ante el cliente,
                antes y después de adquirir nuestros productos, satisfaciendo
                sus necesidades y creando una experiencia positiva.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <strong>Honestidad. </strong> Ser una persona íntegra y
                coherente con los valores de la empresa; actuando con sinceridad
                y respeto hacia el cliente y hacia la empresa.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <strong>Responsabilidad. </strong> Cumplir y actuar con los
                compromisos fijados con la empresa y los clientes.
              </Paragraph>
            </li>
          </ul>
        </RightColumn>
      </Section>
    </CenteredContainer>
  );
};

export default AboutUs;
