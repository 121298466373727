import logo from "./logo.svg";
import "./App.css";

import React from "react";
import Home from "./Home/Home";
import Catalogo from "./Catalogo/Catalogo";
import { BrowserRouter as Switch, Routes, Route } from "react-router-dom";
import Categoria from "./Catalogo/Categoria/Categoria";

const App = () => {
  return (
    <Switch>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/catalogo" element={<Catalogo />} />
        <Route path="/categoria/:id" element={<Categoria />} />
      </Routes>
    </Switch>
  );
};

export default App;
