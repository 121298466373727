import React from "react";
import styled from "styled-components";
import logo from "../images/Group.png";

const Section = styled.section`
  background-color: #92dd31;
  display: flex;
  justify-content: center;
  padding: 20px; /* Ajusta el padding para centrar el contenido */

  @media (min-width: 768px) {
    padding: 0; /* Elimina el padding en pantallas más anchas */
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido verticalmente */

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    width: 50%;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    flex: 2;
    margin-bottom: 0;
    justify-content: flex-end; /* Alinea el logo a la derecha en pantallas más anchas */
  }
`;

const Image = styled.img`
  max-width: 100px;
  height: auto;
`;

const SecondColumn = styled.div`
  flex: 4;
  padding: 20px;
  text-align: center; /* Centra el texto horizontalmente */

  @media (min-width: 768px) {
    text-align: left; /* Alinea el texto a la izquierda en pantallas más anchas */
  }
`;

const Title = styled.h2`
  color: #f2f2f2;
`;

const Paragraph = styled.p`
  color: black;
  font-size: 16px;
`;

const GreenSection = () => {
  return (
    <Section>
      <ColumnsContainer>
        <FirstColumn>
          <Image src={logo} alt="Logotipo" />
        </FirstColumn>
        <SecondColumn>
          <Title>Nosotros</Title>
          <Paragraph>
            Facilitamos la obtención de productos de consumo diario y primera
            necesidad de forma fácil y ágil con ayuda de aplicaciones
            tecnológicas; ahorrando tiempo y costo para beneficio de tu negocio.
          </Paragraph>
        </SecondColumn>
      </ColumnsContainer>
    </Section>
  );
};

export default GreenSection;
