import React from "react";
import styled from "styled-components";

import img1 from "../images/Catalogo/1.jpg";
import img2 from "../images/Catalogo/2.jpg";
import img3 from "../images/Catalogo/3.jpg";
import img4 from "../images/Catalogo/4.jpg";
import img5 from "../images/Catalogo/5.jpg";
import img6 from "../images/Catalogo/6.jpg";
import img7 from "../images/Catalogo/7.jpg";
import img8 from "../images/Catalogo/8.jpg";
import img9 from "../images/Catalogo/9.jpg";
import img10 from "../images/Catalogo/10.jpg";
import Block from "./Block";
import Navbar from "../components/Navbar";
import FooterSection from "../components/FooterSection";
import GreenSection from "../components/GreenSection";
import ContactSection from "../components/ContactSection";

import vectorSVG from "../images/Vectorvector.svg";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div``;

const Header = styled.div`
  padding-top: 3%;
  min-height: 20vh;
  width: 100%;
  height: 15vw;
  flex-shrink: 0;
  background: linear-gradient(180deg, #91dc30 0%, #5fc62f 38.54%, #50b85b 100%);
  @media (max-width: 768px) {
    height: 30vw;
  }
`;

const Title = styled.p`
  color: #fff;
  text-align: center;
  font-family: Dosis;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0;
  letter-spacing: 0.09563rem;
`;

const Texto = styled.p`
  color: #152500;
  text-align: center;

  /* Dosis-Desktop/title-2col-semibold */
  font-family: Dosis;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.05813rem;
`;

const Cuerpo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const VectorBackground = styled.div`
  position: relative;
  bottom: 165px;
  left: 0;
  width: 100%;
  height: 100%; /* Ajusta la altura para que se sobreponga en el fondo de Hero */
  pointer-events: none;
  background-image: url(${vectorSVG});
  background-size: contain;
  background-repeat: repeat-x;
`;

const Data = [
  { id: 1, titulo: "Abarrotes", imagen: img1 },
  { id: 2, titulo: "Bebidas de baja graduación", imagen: img2 },
  { id: 3, titulo: "Bebidas energizantes", imagen: img3 },
  { id: 4, titulo: "Confitería", imagen: img4 },
  { id: 5, titulo: "Estética automotriz", imagen: img5 },
  { id: 6, titulo: "Higiene y cuidado personal", imagen: img6 },
  { id: 7, titulo: "Hogar y tecnoogía", imagen: img7 },
  { id: 8, titulo: "Medicamentos", imagen: img8 },
  { id: 9, titulo: "Productos de limpieza", imagen: img9 },
  { id: 10, titulo: "Quesos y embutidos", imagen: img10 },
];

const Catalogo = () => {
  const contacto = useRef();
  const navigateTo = useNavigate();

  const handleClickNosotros = () => {
    navigateTo("/#nosotros");
  };

  const handleClickServicios = () => {
    navigateTo("/#serv");
  };

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Wrapper>
      <Header>
        <Navbar
          handleClickServicios={handleClickServicios}
          handleClickNosotros={handleClickNosotros}
          handleClickContacto={handleClickContacto}
        />
        <Title>¡Los mejores productos </Title>
        <Title> en un solo lugar!</Title>
        <VectorBackground />
      </Header>
      <Texto>Elige una categoria</Texto>
      <Cuerpo>
        {Data.map((item) => (
          <Block key={item.id} item={item} />
        ))}
      </Cuerpo>
      <ContactSection />
      <GreenSection />
      <FooterSection reference={contacto} />
    </Wrapper>
  );
};
export default Catalogo;
