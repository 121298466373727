import React from "react";
import styled from "styled-components";
import Navbar from "../../components/Navbar";
import ContactSection from "../../components/ContactSection";
import GreenSection from "../../components/GreenSection";
import FooterSection from "../../components/FooterSection";

import vectorSVG from "../../images/Vectorvector.svg";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const Wrapper = styled.div``;
const Header = styled.div`
  padding-top: 3%;
  width: 100%;
  height: 15vw;
  flex-shrink: 0;
  background: linear-gradient(180deg, #91dc30 0%, #5fc62f 38.54%, #50b85b 100%);
  @media (max-width: 768px) {
    height: 30vw;
  }
`;

const Title = styled.p`
  color: #fff;
  text-align: center;
  font-family: Dosis;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0;
  letter-spacing: 0.09563rem;
`;

const Texto = styled.p`
  color: #152500;
  text-align: left;

  /* Dosis-Desktop/title-2col-semibold */
  font-family: Dosis;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.05813rem;
`;

const Cuerpo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 10%;
  margin-right: 10%;
`;

const VectorBackground = styled.div`
  position: absolute;
  bottom: 63%;
  left: 0;
  width: 100%;
  height: 50%; /* Ajusta la altura para que se sobreponga en el fondo de Hero */
  pointer-events: none;
  background-image: url(${vectorSVG});
  background-size: contain;
  background-repeat: repeat-x;
  z-index: 0;
  @media (max-width: 768px) {
    bottom: 64%;
  }
  @media (min-width: 1920px) {
    bottom: 65.5%;
  }
`;

const SmallText = styled.p`
  color: #707070;

  /* Dosis-Desktop/body-3col-medium */
  font-family: Dosis;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem; /* 136% */
  letter-spacing: 0.04688rem;
`;

const TitlesWrap = styled.div`
  margin-left: 10%;
`;

const Categoria = (props) => {
  const { id } = useParams();
  const contacto = useRef();
  const navigateTo = useNavigate();

  useEffect(() => {
    console.log(id);
  }, [id]);

  const handleClickNosotros = () => {
    navigateTo("/#nosotros");
  };

  const handleClickServicios = () => {
    navigateTo("/#servicios");
  };

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Wrapper>
      <Header>
        <Navbar
          handleClickServicios={handleClickServicios}
          handleClickNosotros={handleClickNosotros}
          handleClickContacto={handleClickContacto}
        />
        <Title>¡Los mejores productos </Title>
        <Title> en un solo lugar!</Title>
        <VectorBackground />
      </Header>
      <TitlesWrap>
        <SmallText>Categoría</SmallText>
        <Texto>Medicamentos</Texto>
      </TitlesWrap>

      <Cuerpo></Cuerpo>
      <ContactSection />
      <GreenSection />
      <FooterSection />
    </Wrapper>
  );
};

export default Categoria;
