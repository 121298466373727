import React from "react";
import styled from "styled-components";
import vectorSVG from "../images/Vectorvector.svg";

const HeroContainer = styled.div`
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;

const TextWrap = styled.div`
  margin-left: 10%;
  position: relative; /* Add this to establish a stacking context */
  z-index: 1; /* Add this to place the text content above the background */
`;

const HeroTitle = styled.h1`
  width: 40%;
  color: #60c730;

  /* Dosis-Desktop/title-3col-semibold */
  font-family: Dosis;
  font-size: 3.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.11813rem;
  margin-bottom: 10px;
`;

const HeroSubtitle = styled.p`
  width: 45%;
  margin-top: -15px;
  color: #fff;

  /* Dosis-Desktop/body-3col-medium */
  font-family: Dosis;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem; /* 136% */
  letter-spacing: 0.04688rem;
  justify-content: left;
  margin-bottom: 40px;
`;

const HeroButton = styled.a`
  padding: 10px 40px;
  color: #fff;
  text-align: center;

  /* Dosis-Desktop/label-semibold */
  font-family: Dosis;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03938rem;
  text-decoration: none;
  border-radius: 1.875rem;
  background: linear-gradient(180deg, #ff8925 0%, #ff7425 100%);

  /* shadow-boton */
  box-shadow: 0px 3px 7px 0px rgba(21, 37, 0, 0.15);
  width: 12.5rem;
  height: 3.125rem;
  flex-shrink: 0;
`;

const DashedLine = styled.hr`
  width: 35%;
  margin-left: 0;
  margin-bottom: 8%;
  border: none;
  height: 1px;
  background: transparent;
  border-top: 2px dotted #ffffff;
`;

const Hero = () => {
  return (
    <>
      <HeroContainer>
        <TextWrap>
          <HeroTitle>Vender de León</HeroTitle>
          <HeroSubtitle>
            Llevamos los mejores productos a tu negocio
          </HeroSubtitle>
          <DashedLine></DashedLine>
          <HeroButton href="#conocenos">Conócenos</HeroButton>
        </TextWrap>
      </HeroContainer>
    </>
  );
};

export default Hero;
