import React from "react";
import styled from "styled-components";
import dot from "../images/Catalogo/dot.svg";
import button from "../images/Catalogo/button.svg";

const Wrapper = styled.div`
  margin: 1%;
  position: relative; /* Añade posicionamiento relativo al Wrapper */
`;

const ImageContainer = styled.div`
  border-radius: 1.25rem;
  border: 4px solid #ddd;
  background: #fff;
  width: 25vw;
  height: 15vw;
  /* shadow-cajas */
  box-shadow: 0px 3px 15px 0px rgba(21, 37, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative; /* Añade posicionamiento relativo al ImageContainer */
`;

const Image = styled.img`
  width: 25vw;
`;

const Title = styled.p`
  color: var(--color-de-app-verde-oscuro, #152500);

  /* Dosis-Desktop/title-2col-semibold */
  font-family: Dosis;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.05813rem;
`;

const DotTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Dot = styled.img`
  width: 0.7vw;
  margin-right: 1%;
`;

const Button = styled.img`
  position: absolute; /* Utiliza posicionamiento absoluto */
  bottom: 0px; /* Ajusta la posición vertical según tus preferencias */
  right: 0px; /* Ajusta la posición horizontal según tus preferencias */
  fill: var(
    --color-de-app-naranja-gradiente,
    linear-gradient(180deg, #ff8925 0%, #ff7425 100%)
  );
`;

const ButtonVer = styled.p`
  position: absolute;
  color: var(--color-de-app-blanco, #fff);
  font-size: 1.4625rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.04688rem;
  z-index: 1;
  bottom: -10px;
  right: 30px;
  cursor: pointer;
`;

const Block = (props) => {
  return (
    <Wrapper>
      <ImageContainer>
        <Image src={props.item.imagen} alt="imagen categoria" />
        <Button src={button} alt="button" />
        <ButtonVer>
          Ver <span>&gt;</span>
        </ButtonVer>
      </ImageContainer>
      <DotTextContainer>
        <Dot src={dot} />
        <Title>{props.item.titulo}</Title>
      </DotTextContainer>
    </Wrapper>
  );
};

export default Block;
