import React from "react";
import styled from "styled-components";

const Section = styled.section`
  display: flex;
  background-color: black;
  padding: 20px;
  color: white;
  text-align: center;
`;

const LeftContent = styled.div`
  flex: 1;
  text-align: left;
`;

const RightContent = styled.div`
  flex: 1;
  text-align: right;
`;

const Copyright = styled.p`
  margin: 0 15%;
`;

const TextContainer = styled.span`
  display: inline-block;
  margin-left: 10%;
  margin-right: 5%;
`;

const FooterSection = (props) => {
  return (
    <Section ref={props.reference}>
      <LeftContent>
        <Copyright>
          © Vender De León 2023. Todos los derechos reservados.
        </Copyright>
      </LeftContent>
      <RightContent>
        <TextContainer>Términos y condiciones</TextContainer>
        <TextContainer>Aviso de privacidad</TextContainer>
      </RightContent>
    </Section>
  );
};

export default FooterSection;
