import React, { useState } from "react";
import styled from "styled-components";
import logo from "../images/logo (1).png";
import Icono from "../images/phone_android_black_24dp 1.png";

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  * {
    z-index: 1;
  }
`;

const Nav = styled.nav`
  background-color: white;
  display: flex;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: fit-content;
  border-radius: 10px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.9375rem;
  background: #fff;

  /* shadow-cajas */
  box-shadow: 0px 3px 15px 0px rgba(21, 37, 0, 0.15);
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  padding-right: 2%;
  &:not(:last-child) {
    border-right: 1.5px dotted black;
  }
`;

const LogoImage = styled.img`
  margin-right: 10px;
`;

const NavLinkContainer = styled.div`
  display: flex;
  align-items: center;
  color: #152500;
  text-align: center;

  /* Dosis-Desktop/label-semibold */
  font-family: Dosis;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03938rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    display: ${({ showMenu }) => (showMenu ? "flex" : "none")};
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;
  margin-left: 3rem;

  &:hover {
    color: #92dd31; /* Cambiamos el color del texto a verde en hover */
  }

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const NavLink2 = styled.a`
  text-decoration: none;
  color: black;
`;

const Icon = styled.img`
  width: 15%;
  margin-right: 5px;
`;

const DBlock = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: right;
  margin-left: 90px;
`;

const HamburgerIcon = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 20px;
    position: relative;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: black;
      border-radius: 9px;
      opacity: 1;
      left: 20px;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0px;
    }
    span:nth-child(2) {
      top: 10px;
    }
    span:nth-child(3) {
      top: 20px;
    }
    ${({ showMenu }) =>
      showMenu &&
      `
      span:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
      }
    `}
  }
`;

const LogoAndIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Navbar = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <CenteredContainer>
      <Nav>
        <LogoAndIconContainer onClick={toggleMenu}>
          <LogoContainer>
            <LogoImage src={logo} alt="Logo" />
          </LogoContainer>

          <HamburgerIcon showMenu={showMenu} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </HamburgerIcon>
        </LogoAndIconContainer>

        <NavLinkContainer showMenu={showMenu}>
          <NavLink href="/">Inicio</NavLink>
          <NavLink href={"#nosotros"} onClick={props.handleClickNosotros}>
            Nosotros
          </NavLink>
          <NavLink href={"#serv"} onClick={props.handleClickServicios}>
            Servicios
          </NavLink>
          <NavLink href={"/catalogo"}>Catalogo</NavLink>
          <NavLink href={"#contacto"} onClick={props.handleClickContacto}>
            Contacto
          </NavLink>
          <DBlock>
            <Icon src={Icono} alt="Cell Icon" />
            <NavLink2
              href="https://playstore-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              ¡Descarga nuestra app!
            </NavLink2>
          </DBlock>
        </NavLinkContainer>
      </Nav>
    </CenteredContainer>
  );
};

export default Navbar;
